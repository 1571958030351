/*
 * SettingApplicationForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file SettingApplicationForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { Fieldset } from 'primereact/fieldset';
import i18n from '../../../Services/I18n';
import { IApplicationSettingFormValues } from './ISettingFormValues';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import SettingFavicon from './SettingFavicon';
import SettingLogo from './SettingLogo';
import { ILicenseStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { defaultRenewalRefreshTokenPeriodInSeconds } from '@abstract/abstractwebcommon-shared/constants';
import Button from 'react-bootstrap/Button';
import { ISetting } from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import { ImageUploadAction } from './SettingPage';
import FieldSkeleton, {
  SkeletonHeight,
  controlInputClassName
} from '@abstract/abstractwebcommon-client/SkeletonLoader/FieldSkeleton/FieldSkeleton';
import PopupInformation from '@abstract/abstractwebcommon-client/FormControl/PopupInformation';
import TextEditorDialog from '@abstract/abstractwebcommon-client/TextEditorDialog/TextEditorDialog';

interface ISettingOtherProperties {
  handleSubmit: (data: any) => void;
  handleFavouriteIconUpload: (file: any) => void;
  handleFavouriteIconDelete: (event: any) => void;
  displayCroppedFavouriteIcon: any;
  handleLogoUpload: (file: any) => void;
  handleLogoDelete: (event: any) => void;
  displayCroppedLogo: any;
  fetchLicenseDropdown: (filter: string) => void;
  isLoading?: boolean;
  settingsState?: any;
  CSSTemplates?: any;
  logoUploadStatus?: ImageUploadAction /**< Logo upload Status */;
  favouriteIconUploadStatus?: ImageUploadAction /**< Favourite icon upload Status */;
}

interface ISettingMyFormProperties {
  initialLicenseEncryptionKey?: string;
  initialRssFeedUrl?: string;
  initialtemplateLicenseUUID?: any;
  initialApplicationTitle?: string;
  initialUserAuthenticationVerificationURL?: string;
  initialSelectedCSSTemplate?: any;
  initialCronJobSchedule?: string;
  initialRenewalRefreshTokenPeriodInSeconds?: number;
  initialCacheFeedsTTL?: number;
  initialEcommerceBaseURL?: string;
  initialAdminEmail?: string;
  initialActivateLicenseFallbackURL?: string;
  initialExperimentalFeatures?: string;
  initialRssDelay?: number;
  initialFrontendBaseURL?: string;
  initialMigrationContactEmail?: string;
  initialNumberOfNewsLetterEmailsSentPerMinute?: number;
  initialLicenseBaseURL?: string;
  initialSupportEntitlementUUID?: string;
  initialSublicenseEntitlementUUID?: string;
  initialFloatingEntitlementUUID?: string;
  initialJobsLockDuration?: number;
  initialFloatingLicenseCooldownActivation?: number;
  initialEulaEntitlementUUID?: string;
  initialSLAEntitlementUUID?: string;
  initialEulaTermsText?: string;
  initialSLATermsText?: string;
  initialBadgeGrantingProductNameRegex?: string;
  initialPaidLicenseDiscourseBadgeID?: number;
  initialDiscourseEndpointURL?: string;
  initialDiscourseAPIKey?: string;
  initialCDNBaseURL?: string;
  handleSubmit: (data: any) => void;
  handleFavouriteIconUpload: (file: any) => void;
  handleFavouriteIconDelete: (event: any) => void;
  displayCroppedFavouriteIcon: any;
  handleLogoUpload: (file: any) => void;
  handleLogoDelete: (event: any) => void;
  displayCroppedLogo: any;
  fetchLicenseDropdown: (filter: string) => void;
  CSSTemplates?: any;
  isLoading?: boolean;
  settingsState?: any;
  logoImageName?: string;
  favouriteIconImageName?: string;
  initialSupportRoleUUID?: string;
  logoUploadStatus?: ImageUploadAction /**< Logo upload Status */;
  favouriteIconUploadStatus?: ImageUploadAction /**< Favourite icon upload Status */;
}

const SettingApplicationForm = (
  properties: ISettingOtherProperties & FormikProps<IApplicationSettingFormValues>
): JSX.Element => {
  const {
    handleSubmit,
    handleFavouriteIconUpload,
    handleFavouriteIconDelete,
    displayCroppedFavouriteIcon,
    handleLogoUpload,
    handleLogoDelete,
    displayCroppedLogo,
    handleChange,
    setFieldValue,
    handleBlur,
    touched,
    values,
    errors,
    isLoading,
    CSSTemplates,
    settingsState,
    fetchLicenseDropdown,
    logoUploadStatus,
    favouriteIconUploadStatus
  } = properties;
  const { t } = useTranslation();
  const [isListenerAdded, setIsListenerAdded] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const licenseState: ILicenseStateSelector = useSelector(
    (state: IStateSelectors) => state.licenses
  );
  const allLicenseTemplates: any[] = licenseState.templates;
  const [isApplicationSettingsUpdatesExist, setApplicationSettingsUpdatesExist] =
    useState<boolean>(false); /**< isApplicationSettingsUpdatesExist. */
  const isApplicationSettingsErrorsExists: boolean =
    errors && Object.keys(errors).length > 0; /**< isApplicationSettingsErrorsExists. */
  const [isLogoUpdateExists, setLogoUpdateExists] =
    useState<boolean>(false); /**< isLogoUpdateExists. */
  const [isFavouriteIconUpdateExists, setFavouriteIconUpdateExists] =
    useState<boolean>(false); /**< isFavouriteIconUpdateExists. */
  const [isShowEulaTermsTextDialog, setShowEulaTermsTextDialog] =
    useState<boolean>(false); /**< To Show Eula terms text dialog. */
  const [isShowSLATermsTextDialog, setShowSLATermsTextDialog] =
    useState<boolean>(false); /**< To Show SLA terms text dialog. */
  const [eulaTermsTextHTML, setEulaTermsTextHTML] = useState<string>(
    values.eulaTermsText
  ); /**< Eula terms text HTML */
  const [slaTermsTextHTML, setSLATermsTextHTML] = useState<string>(
    values.slaTermsText
  ); /**< SLA terms text HTML */
  const eulaTermsTextHTMLElement: HTMLSpanElement = document.createElement('span');
  const slaTermsTextHTMLElement: HTMLSpanElement = document.createElement('span');
  const [eulaTermsTextValue, setEulaTermsTextValue] =
    useState<string>(); /**< Eula terms text value */
  const [slaTermsTextValue, setSLATermsTextValue] = useState<string>(); /**< SLA terms text value */

  useEffect(() => {
    if (
      (logoUploadStatus === ImageUploadAction.Delete && !settingsState.setting?.logoImageURL) ||
      logoUploadStatus === ImageUploadAction.Unset
    ) {
      setLogoUpdateExists(false);
    } else if (logoUploadStatus) {
      setLogoUpdateExists(true);
    }
  }, [logoUploadStatus]);

  // To check favouriteicon update exists or not.
  useEffect(() => {
    if (
      (favouriteIconUploadStatus === ImageUploadAction.Delete &&
        !settingsState.setting?.favouriteIconImageURL) ||
      favouriteIconUploadStatus === ImageUploadAction.Unset
    ) {
      setFavouriteIconUpdateExists(false);
    } else if (favouriteIconUploadStatus) {
      setFavouriteIconUpdateExists(true);
    }
  }, [favouriteIconUploadStatus]);

  useEffect(() => {
    const licenseFilter: any = document.getElementById('licenseDropdown');
    if (licenseFilter && !isListenerAdded && allLicenseTemplates?.length > 0) {
      licenseFilter.addEventListener('keyup', (event: any) => {
        event.preventDefault();
        const licenseFilterInArray: any = document.getElementsByClassName(
          'p-dropdown-filter p-inputtext p-component'
        );

        if (licenseFilterInArray && licenseFilterInArray[0]) {
          const licenseFilterIn = licenseFilterInArray[0];
          const filter = licenseFilterIn.value;
          setIsListenerAdded(true);
          fetchLicenseDropdown(filter);
        }
      });
    }
  }, [allLicenseTemplates, fetchLicenseDropdown, isListenerAdded]);

  useEffect(() => {
    eulaTermsTextHTMLElement.innerHTML = values.eulaTermsText;
    setEulaTermsTextValue(eulaTermsTextHTMLElement.innerText);
    setEulaTermsTextHTML(values.eulaTermsText);
  }, [values.eulaTermsText]);

  useEffect(() => {
    slaTermsTextHTMLElement.innerHTML = values.slaTermsText;
    setSLATermsTextValue(slaTermsTextHTMLElement.innerText);
    setSLATermsTextHTML(values.slaTermsText);
  }, [values.slaTermsText]);

  /// show save popup
  const saveBtnClick = (event: any): void => {
    if (String(values.renewalRefreshTokenPeriodInSeconds).length !== 0) {
      setShowConfirmation(true);
      setConfirmPopupTarget(event?.target);
    }
  };

  /// save on Accept
  const onAccept = (): void => {
    setShowConfirmation(false);
    handleSubmit();
    setApplicationSettingsUpdatesExist(false);
    setLogoUpdateExists(false);
    setFavouriteIconUpdateExists(false);
  };

  /// Hide confirmation on reject
  const onReject = (): void => {
    setShowConfirmation(false);
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = (): JSX.Element => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={t('/confirm_messages.save_record')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  /// Handle change event
  const onChange = (event: any): void => {
    handleChange(event);
    revalidateApplicationSettingsUpdates(event.target);
  };

  /// Set Field value
  const setFieldValueLocal = (key: string, value: any): void => {
    setFieldValue(key, value);
    revalidateApplicationSettingsUpdates({
      id: key,
      value
    });
  };

  /// Validate ApplicationSettings updates
  const revalidateApplicationSettingsUpdates = (currentUpdate: any): void => {
    const applicationSettingsValues: ISetting = { ...values };
    if (applicationSettingsValues) {
      applicationSettingsValues[currentUpdate.id] = currentUpdate.value;
    }
    setApplicationSettingsUpdatesExist(
      checkIfApplicationSettingsUpdatesExists(applicationSettingsValues)
    );
  };

  /// Check ApplicationSettings updates exists or not.
  const checkIfApplicationSettingsUpdatesExists = (
    applicationSettingsValues: ISetting
  ): boolean => {
    return (
      applicationSettingsValues &&
      Object.keys(mapApplicationSettingsValues(applicationSettingsValues)).length !== 0
    );
  };

  /// ApplicationSettings Values
  const mapApplicationSettingsValues = (applicationSettingsValues: ISetting) => {
    const payload: ISetting = {};
    const setting: ISetting = settingsState.setting; /**< Setting */
    if (!setting) {
      return {};
    }
    if (setting.licenseEncryptionKey !== applicationSettingsValues.licenseEncryptionKey) {
      payload['licenseEncryptionKey'] = applicationSettingsValues.licenseEncryptionKey;
    }
    if (setting.rssFeedUrl !== applicationSettingsValues.rssFeedUrl) {
      payload['rssFeedUrl'] = applicationSettingsValues.rssFeedUrl;
    }
    if (setting.applicationTitle !== applicationSettingsValues.applicationTitle) {
      payload['applicationTitle'] = applicationSettingsValues.applicationTitle;
    }
    if (
      setting.templateLicenseUUID?.licenseUUID !==
      applicationSettingsValues.templateLicenseUUID?.licenseUUID
    ) {
      payload['templateLicenseUUID'] = applicationSettingsValues.templateLicenseUUID;
    }
    if (
      setting.userAuthenticationVerificationURL !==
      applicationSettingsValues.userAuthenticationVerificationURL
    ) {
      payload['userAuthenticationVerificationURL'] =
        applicationSettingsValues.userAuthenticationVerificationURL;
    }
    if (
      setting.selectedCSSTemplate?.templateUUID !==
      applicationSettingsValues.selectedCSSTemplate?.templateUUID
    ) {
      payload['selectedCSSTemplate'] = applicationSettingsValues.selectedCSSTemplate;
    }
    if (setting.cronJobSchedule != applicationSettingsValues.cronJobSchedule) {
      payload['cronJobSchedule'] = applicationSettingsValues.cronJobSchedule;
    }
    if (setting.supportRoleUUID !== applicationSettingsValues.supportRoleUUID) {
      payload['supportRoleUUID'] = applicationSettingsValues.supportRoleUUID;
    }
    if (
      setting.renewalRefreshTokenPeriodInSeconds !=
      applicationSettingsValues.renewalRefreshTokenPeriodInSeconds
    ) {
      payload['renewalRefreshTokenPeriodInSeconds'] =
        applicationSettingsValues.renewalRefreshTokenPeriodInSeconds;
    }
    if (setting.cacheFeedsTTL != applicationSettingsValues.cacheFeedsTTL) {
      payload['cacheFeedsTTL'] = applicationSettingsValues.cacheFeedsTTL;
    }
    if (setting.ecommerceBaseURL !== applicationSettingsValues.ecommerceBaseURL) {
      payload['ecommerceBaseURL'] = applicationSettingsValues.ecommerceBaseURL;
    }
    if (setting.adminEmail !== applicationSettingsValues.adminEmail) {
      payload['adminEmail'] = applicationSettingsValues.adminEmail;
    }
    if (
      setting.activateLicenseFallbackURL !== applicationSettingsValues.activateLicenseFallbackURL
    ) {
      payload['activateLicenseFallbackURL'] = applicationSettingsValues.activateLicenseFallbackURL;
    }
    if (setting.experimentalFeatures !== applicationSettingsValues.experimentalFeatures) {
      payload['experimentalFeatures'] = applicationSettingsValues.experimentalFeatures;
    }
    if (setting.rssDelay != applicationSettingsValues.rssDelay) {
      payload['rssDelay'] = applicationSettingsValues.rssDelay;
    }
    if (setting.frontendBaseURL !== applicationSettingsValues.frontendBaseURL) {
      payload['frontendBaseURL'] = applicationSettingsValues.frontendBaseURL;
    }
    if (setting.migrationContactEmail != applicationSettingsValues.migrationContactEmail) {
      payload['migrationContactEmail'] = applicationSettingsValues.migrationContactEmail;
    }
    if (
      setting.numberOfNewsLetterEmailsSentPerMinute !=
      applicationSettingsValues.numberOfNewsLetterEmailsSentPerMinute
    ) {
      payload['numberOfNewsLetterEmailsSentPerMinute'] =
        applicationSettingsValues.numberOfNewsLetterEmailsSentPerMinute;
    }
    if (setting.licenseBaseURL !== applicationSettingsValues.licenseBaseURL) {
      payload['licenseBaseURL'] = applicationSettingsValues.licenseBaseURL;
    }
    if (setting.supportEntitlementUUID !== applicationSettingsValues.supportEntitlementUUID) {
      payload['supportEntitlementUUID'] = applicationSettingsValues.supportEntitlementUUID;
    }
    if (setting.sublicenseEntitlementUUID !== applicationSettingsValues.sublicenseEntitlementUUID) {
      payload['sublicenseEntitlementUUID'] = applicationSettingsValues.sublicenseEntitlementUUID;
    }
    if (setting.floatingEntitlementUUID !== applicationSettingsValues.floatingEntitlementUUID) {
      payload['floatingEntitlementUUID'] = applicationSettingsValues.floatingEntitlementUUID;
    }
    if (setting.jobsLockDuration != applicationSettingsValues.jobsLockDuration) {
      payload['jobsLockDuration'] = applicationSettingsValues.jobsLockDuration;
    }
    if (
      setting.floatingLicenseCooldownActivation !=
      applicationSettingsValues.floatingLicenseCooldownActivation
    ) {
      payload['floatingLicenseCooldownActivation'] =
        applicationSettingsValues.floatingLicenseCooldownActivation;
    }
    if (setting.eulaEntitlementUUID !== applicationSettingsValues.eulaEntitlementUUID) {
      payload['eulaEntitlementUUID'] = applicationSettingsValues.eulaEntitlementUUID;
    }
    if (setting.slaEntitlementUUID !== applicationSettingsValues.slaEntitlementUUID) {
      payload['slaEntitlementUUID'] = applicationSettingsValues.slaEntitlementUUID;
    }
    if (setting.eulaTermsText !== applicationSettingsValues.eulaTermsText) {
      payload['eulaTermsText'] = applicationSettingsValues.eulaTermsText;
    }
    if (setting.slaTermsText !== applicationSettingsValues.slaTermsText) {
      payload['slaTermsText'] = applicationSettingsValues.slaTermsText;
    }
    if (
      setting.badgeGrantingProductNameRegex !==
      applicationSettingsValues.badgeGrantingProductNameRegex
    ) {
      payload['badgeGrantingProductNameRegex'] =
        applicationSettingsValues.badgeGrantingProductNameRegex;
    }
    if (
      setting.paidLicenseDiscourseBadgeID !== applicationSettingsValues.paidLicenseDiscourseBadgeID
    ) {
      payload['paidLicenseDiscourseBadgeID'] =
        applicationSettingsValues.paidLicenseDiscourseBadgeID;
    }
    if (setting.discourseEndpointURL !== applicationSettingsValues.discourseEndpointURL) {
      payload['discourseEndpointURL'] = applicationSettingsValues.discourseEndpointURL;
    }
    if (setting.discourseAPIKey !== applicationSettingsValues.discourseAPIKey) {
      payload['discourseAPIKey'] = applicationSettingsValues.discourseAPIKey;
    }
    if (setting.cdnBaseURL !== applicationSettingsValues.cdnBaseURL) {
      payload['cdnBaseURL'] = applicationSettingsValues.cdnBaseURL;
    }

    return payload;
  };
  const RenderSkeletonComponent = (isFileInput = false): JSX.Element =>
    !settingsState.setting && (
      <FieldSkeleton
        skeletonHeightType={isFileInput ? SkeletonHeight.FileType : SkeletonHeight.Default}
      />
    );

  const changeInputClassName = (isFileField = false): string =>
    controlInputClassName(!settingsState.setting, isFileField);

  return (
    <Form>
      <Fieldset legend={t('admin.page.settings.category.applicationSettings')}>
        <div className="mb-2">
          <SettingLogo
            handleLogoUpload={handleLogoUpload}
            handleLogoDelete={handleLogoDelete}
            settingsState={settingsState}
            displayCroppedLogo={displayCroppedLogo}
            changeInputClassName={changeInputClassName}>
            {RenderSkeletonComponent(true)}
          </SettingLogo>
          <SettingFavicon
            handleFavouriteIconUpload={handleFavouriteIconUpload}
            handleFavouriteIconDelete={handleFavouriteIconDelete}
            settingsState={settingsState}
            displayCroppedFavouriteIcon={displayCroppedFavouriteIcon}
            changeInputClassName={changeInputClassName}>
            {RenderSkeletonComponent(true)}
          </SettingFavicon>
        </div>
        <Row>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.licenseEncryptionKey')}
              name="licenseEncryptionKey"
              id={'licenseEncryptionKey'}
              isLoading={isLoading}
              type="password"
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.licenseEncryptionKey}
              errors={errors.licenseEncryptionKey}
              value={values.licenseEncryptionKey}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.licenseEncryptionKey_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.rssFeedUrl')}
              name="rssFeedUrl"
              id={'rssFeedUrl'}
              isLoading={isLoading}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.rssFeedUrl}
              errors={errors.rssFeedUrl}
              value={values.rssFeedUrl}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.rssFeedUrl_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.cacheFeedsTTL')}
              id={'cacheFeedsTTL'}
              errors={errors.cacheFeedsTTL}
              name={'cacheFeedsTTL'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.cacheFeedsTTL}
              value={values['cacheFeedsTTL']}
              type="number"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.cacheFeedsTTL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.rssDelay')}
              id={'rssDelay'}
              errors={errors.rssDelay}
              name={'rssDelay'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.rssDelay}
              value={values['rssDelay']}
              type="number"
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.rssDelay_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.applicationTitle')}
              name="applicationTitle"
              id={'applicationTitle'}
              isLoading={isLoading}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.applicationTitle}
              errors={errors.applicationTitle}
              value={values.applicationTitle}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.applicationTitle_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.userAuthenticationVerificationURL')}
              name="userAuthenticationVerificationURL"
              id={'userAuthenticationVerificationURL'}
              isLoading={isLoading}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.userAuthenticationVerificationURL}
              errors={errors.userAuthenticationVerificationURL}
              value={values.userAuthenticationVerificationURL}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.userAuthenticationVerificationURL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              type="string"
              label={t('admin.page.settings.form.cronJobSchedule')}
              name="cronJobSchedule"
              id={'cronJobSchedule'}
              isLoading={isLoading}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.cronJobSchedule}
              errors={errors.cronJobSchedule}
              value={values.cronJobSchedule}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.cronJobSchedule_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.supportRoleUUID')}
              id={'supportRoleUUID'}
              isLoading={isLoading}
              errors={errors.supportRoleUUID}
              name={'supportRoleUUID'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.supportRoleUUID}
              value={values.supportRoleUUID}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.supportRoleUUID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Col md={12} sm={12} className="form-group">
            <Form.Label>{t('admin.page.settings.form.templateLicenseUUID')}</Form.Label>
            <PopupInformation
              id={'templateLicenseUUID'}
              popupText={t('admin.page.settings.form.templateLicenseUUID_popupText')}
            />
            <Dropdown
              filter
              id="licenseDropdown"
              optionLabel="projectName"
              value={values.templateLicenseUUID}
              options={allLicenseTemplates?.length === 0 ? [{}] : allLicenseTemplates}
              placeholder={t('admin.page.settings.form.selectDefaultTemplate')}
              onChange={(event: any) => {
                if (!event.value) {
                  setFieldValueLocal('templateLicenseUUID', null);
                } else {
                  setFieldValueLocal('templateLicenseUUID', event.value);
                }
              }}
              className={changeInputClassName()}
              showClear={values.templateLicenseUUID ? true : false}
            />
            {RenderSkeletonComponent()}
          </Col>
          <Col md={12} sm={12} className="form-group">
            <Form.Label>{t('admin.page.settings.form.selectedCSSTemplate')}</Form.Label>
            <PopupInformation
              id={'selectedCSSTemplate'}
              popupText={t('admin.page.settings.form.selectedCSSTemplate_popupText')}
            />
            <Dropdown
              filter
              id="selectedCSSTemplateDropdown"
              optionValue="templateUUID"
              optionLabel="templateUUID"
              value={values.selectedCSSTemplate && values.selectedCSSTemplate.templateUUID}
              options={CSSTemplates?.length === 0 ? [{}] : CSSTemplates}
              placeholder={t('admin.page.settings.form.selectDefaultTemplate')}
              onChange={(event: any) => setFieldValueLocal('selectedCSSTemplate', event.value)}
              className={changeInputClassName()}
            />
            {RenderSkeletonComponent()}
          </Col>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.renewalRefreshTokenPeriodInSeconds')}
              id={'renewalRefreshTokenPeriodInSeconds'}
              errors={errors.renewalRefreshTokenPeriodInSeconds}
              name={'renewalRefreshTokenPeriodInSeconds'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.renewalRefreshTokenPeriodInSeconds}
              value={values['renewalRefreshTokenPeriodInSeconds']}
              type="number"
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.renewalRefreshTokenPeriodInSeconds_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.ecommerceBaseURL')}
              id={'ecommerceBaseURL'}
              errors={errors.ecommerceBaseURL}
              name={'ecommerceBaseURL'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.ecommerceBaseURL}
              value={values['ecommerceBaseURL']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.ecommerceBaseURL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.adminEmail')}
              id={'adminEmail'}
              errors={errors.adminEmail}
              name={'adminEmail'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.adminEmail}
              value={values['adminEmail']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.adminEmail_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.activateLicenseFallbackURL')}
              id={'activateLicenseFallbackURL'}
              errors={errors.activateLicenseFallbackURL}
              name={'activateLicenseFallbackURL'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.activateLicenseFallbackURL}
              value={values['activateLicenseFallbackURL']}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.activateLicenseFallbackURL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.experimentalFeatures')}
              id={'experimentalFeatures'}
              errors={errors.experimentalFeatures}
              name={'experimentalFeatures'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.experimentalFeatures}
              value={values['experimentalFeatures']}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.experimentalFeatures_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.frontendBaseURL')}
              id={'frontendBaseURL'}
              errors={errors.frontendBaseURL}
              name={'frontendBaseURL'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.frontendBaseURL}
              value={values['frontendBaseURL']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.frontendBaseURL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.migrationContactEmail')}
              id={'migrationContactEmail'}
              errors={errors.migrationContactEmail}
              name={'migrationContactEmail'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.migrationContactEmail}
              value={values['migrationContactEmail']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.migrationContactEmail_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.numberOfNewsLetterEmailsSentPerMinute')}
              id={'numberOfNewsLetterEmailsSentPerMinute'}
              errors={errors.numberOfNewsLetterEmailsSentPerMinute}
              name={'numberOfNewsLetterEmailsSentPerMinute'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.numberOfNewsLetterEmailsSentPerMinute}
              value={values['numberOfNewsLetterEmailsSentPerMinute']}
              type="number"
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t(
                'admin.page.settings.form.numberOfNewsLetterEmailsSentPerMinute_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.licenseBaseURL')}
              id={'licenseBaseURL'}
              errors={errors.licenseBaseURL}
              name={'licenseBaseURL'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.licenseBaseURL}
              value={values['licenseBaseURL']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.licenseBaseURL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.supportEntitlementUUID')}
              id={'supportEntitlementUUID'}
              errors={errors.supportEntitlementUUID}
              name={'supportEntitlementUUID'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.supportEntitlementUUID}
              value={values['supportEntitlementUUID']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.supportEntitlementUUID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.sublicenseEntitlementUUID')}
              id={'sublicenseEntitlementUUID'}
              errors={errors.sublicenseEntitlementUUID}
              name={'sublicenseEntitlementUUID'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.sublicenseEntitlementUUID}
              value={values['sublicenseEntitlementUUID']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.sublicenseEntitlementUUID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.floatingEntitlementUUID')}
              id={'floatingEntitlementUUID'}
              errors={errors.floatingEntitlementUUID}
              name={'floatingEntitlementUUID'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.floatingEntitlementUUID}
              value={values['floatingEntitlementUUID']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.floatingEntitlementUUID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.jobsLockDuration')}
              id={'jobsLockDuration'}
              type="number"
              errors={errors.jobsLockDuration}
              name={'jobsLockDuration'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.jobsLockDuration}
              value={values['jobsLockDuration']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.jobsLockDuration_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.floatingLicenseCooldownActivation')}
              id={'floatingLicenseCooldownActivation'}
              type="number"
              errors={errors.floatingLicenseCooldownActivation}
              name={'floatingLicenseCooldownActivation'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.floatingLicenseCooldownActivation}
              value={values['floatingLicenseCooldownActivation']}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.floatingLicenseCooldownActivation_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.eulaEntitlementUUID')}
              id={'eulaEntitlementUUID'}
              errors={errors.eulaEntitlementUUID}
              name={'eulaEntitlementUUID'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.eulaEntitlementUUID}
              value={values['eulaEntitlementUUID']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.eulaEntitlementUUID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.eulaTermsText')}
              name={'eulaTermsText'}
              id={'eulaTermsText'}
              onBlur={handleBlur}
              touched={touched.eulaTermsText}
              errors={errors.eulaTermsText}
              value={eulaTermsTextValue}
              spanClassName={changeInputClassName()}
              labelClassName="required"
              isShowInformationPopup={true}
              placeholder={i18n.t('admin.page.settings.form.eulaTermsTextPlaceholder')}
              onClick={() => setShowEulaTermsTextDialog(true)}
              role="button"
              readOnly
              popupText={t('admin.page.settings.form.eulaTermsText_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.slaEntitlementUUID')}
              id={'slaEntitlementUUID'}
              errors={errors.slaEntitlementUUID}
              name={'slaEntitlementUUID'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.slaEntitlementUUID}
              value={values['slaEntitlementUUID']}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.slaEntitlementUUID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.slaTermsText')}
              name={'slaTermsText'}
              id={'slaTermsText'}
              onBlur={handleBlur}
              touched={touched.slaTermsText}
              errors={errors.slaTermsText}
              value={slaTermsTextValue}
              spanClassName={changeInputClassName()}
              labelClassName="required"
              isShowInformationPopup={true}
              placeholder={i18n.t('admin.page.settings.form.slaTermsTextPlaceholder')}
              onClick={() => setShowSLATermsTextDialog(true)}
              role="button"
              readOnly
              popupText={t('admin.page.settings.form.slaTermsText_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.badgeGrantingProductNameRegex')}
              id={'badgeGrantingProductNameRegex'}
              errors={errors.badgeGrantingProductNameRegex}
              name={'badgeGrantingProductNameRegex'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.badgeGrantingProductNameRegex}
              value={values['badgeGrantingProductNameRegex']}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={t('admin.page.settings.form.badgeGrantingProductNameRegex_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.paidLicenseDiscourseBadgeID')}
              id={'paidLicenseDiscourseBadgeID'}
              type="number"
              errors={errors.paidLicenseDiscourseBadgeID}
              name={'paidLicenseDiscourseBadgeID'}
              labelClassName="required"
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.paidLicenseDiscourseBadgeID}
              value={values['paidLicenseDiscourseBadgeID']}
              spanClassName={changeInputClassName()}
              isShowInformationPopup
              popupText={t('admin.page.settings.form.paidLicenseDiscourseBadgeID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.discourseEndpointURL')}
              id={'discourseEndpointURL'}
              errors={errors.discourseEndpointURL}
              name={'discourseEndpointURL'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.discourseEndpointURL}
              value={values['discourseEndpointURL']}
              spanClassName={changeInputClassName()}
              isShowInformationPopup
              popupText={t('admin.page.settings.form.discourseEndpointURL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.discourseAPIKey')}
              id={'discourseAPIKey'}
              errors={errors.discourseAPIKey}
              name={'discourseAPIKey'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.discourseAPIKey}
              value={values['discourseAPIKey']}
              spanClassName={changeInputClassName()}
              isShowInformationPopup
              popupText={t('admin.page.settings.form.discourseAPIKey_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.settings.form.cdnBaseURL')}
              id={'cdnBaseURL'}
              errors={errors.cdnBaseURL}
              name={'cdnBaseURL'}
              onBlur={handleBlur}
              onChange={onChange}
              touched={touched.cdnBaseURL}
              value={values['cdnBaseURL']}
              spanClassName={changeInputClassName()}
              isShowInformationPopup
              popupText={t('admin.page.settings.form.cdnBaseURL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Col md={12} sm={12} lg={12} className="d-flex justify-content-center pb-0">
            <Col className="p-0">
              <Button
                className={`w-100 ${
                  isLoading ||
                  showConfirmation ||
                  (!isApplicationSettingsUpdatesExist &&
                    !isLogoUpdateExists &&
                    !isFavouriteIconUpdateExists) ||
                  isApplicationSettingsErrorsExists
                    ? 'custom-disabled-button'
                    : ''
                }`}
                onClick={saveBtnClick}
                disabled={
                  isLoading ||
                  showConfirmation ||
                  (!isApplicationSettingsUpdatesExist &&
                    !isLogoUpdateExists &&
                    !isFavouriteIconUpdateExists) ||
                  isApplicationSettingsErrorsExists
                }>
                {t('admin.page.settings.buttons.save')}
              </Button>
            </Col>
          </Col>
        </Row>
      </Fieldset>
      {getConfirmPopup()}
      <TextEditorDialog
        isShowDialog={isShowEulaTermsTextDialog}
        setShowDialog={setShowEulaTermsTextDialog}
        formikValue={values.eulaTermsText}
        settingValue={settingsState?.setting?.eulaTermsText}
        textHTML={eulaTermsTextHTML}
        setTextHTML={setEulaTermsTextHTML}
        editHeaderText={i18n.t('admin.page.settings.form.edit-eulaTermsText')}
        addHeaderText={i18n.t('admin.page.settings.form.add-eulaTermsText')}
        formikField={'eulaTermsText'}
        isLoading={isLoading}
        setFieldValue={setFieldValue}
        setApplicationSettingsUpdatesExist={setApplicationSettingsUpdatesExist}
      />

      <TextEditorDialog
        isShowDialog={isShowSLATermsTextDialog}
        setShowDialog={setShowSLATermsTextDialog}
        formikValue={values.slaTermsText}
        settingValue={settingsState?.setting?.slaTermsText}
        textHTML={slaTermsTextHTML}
        setTextHTML={setSLATermsTextHTML}
        editHeaderText={i18n.t('admin.page.settings.form.edit-slaTermsText')}
        addHeaderText={i18n.t('admin.page.settings.form.add-slaTermsText')}
        formikField={'slaTermsText'}
        isLoading={isLoading}
        setFieldValue={setFieldValue}
        setApplicationSettingsUpdatesExist={setApplicationSettingsUpdatesExist}
      />
    </Form>
  );
};

const SettingApplication = withFormik<ISettingMyFormProperties, IApplicationSettingFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (properties) => {
    const { setting } = properties.settingsState;
    if (setting) {
      return {
        logoImageName: setting.logoImageName,
        favouriteIconImageName: setting.favouriteIconImageName,
        licenseEncryptionKey: setting.licenseEncryptionKey,
        rssFeedUrl: setting.rssFeedUrl,
        applicationTitle: setting.applicationTitle,
        templateLicenseUUID: setting.templateLicenseUUID,
        userAuthenticationVerificationURL: setting.userAuthenticationVerificationURL,
        selectedCSSTemplate: setting.selectedCSSTemplate,
        cronJobSchedule: setting.cronJobSchedule || '0',
        supportRoleUUID: setting.supportRoleUUID,
        renewalRefreshTokenPeriodInSeconds:
          setting.renewalRefreshTokenPeriodInSeconds && setting.renewalRefreshTokenPeriodInSeconds,
        cacheFeedsTTL: setting.cacheFeedsTTL && setting.cacheFeedsTTL,
        ecommerceBaseURL: setting.ecommerceBaseURL && setting.ecommerceBaseURL,
        adminEmail: setting.adminEmail && setting.adminEmail,
        activateLicenseFallbackURL:
          setting.activateLicenseFallbackURL && setting.activateLicenseFallbackURL,
        experimentalFeatures: setting.experimentalFeatures && setting.experimentalFeatures,
        rssDelay: setting.rssDelay && setting.rssDelay,
        frontendBaseURL: setting.frontendBaseURL && setting.frontendBaseURL,
        migrationContactEmail: setting.migrationContactEmail && setting.migrationContactEmail,
        numberOfNewsLetterEmailsSentPerMinute:
          setting.numberOfNewsLetterEmailsSentPerMinute &&
          setting.numberOfNewsLetterEmailsSentPerMinute,
        licenseBaseURL: setting.licenseBaseURL && setting.licenseBaseURL,
        supportEntitlementUUID: setting.supportEntitlementUUID && setting.supportEntitlementUUID,
        sublicenseEntitlementUUID:
          setting.sublicenseEntitlementUUID && setting.sublicenseEntitlementUUID,
        floatingEntitlementUUID: setting.floatingEntitlementUUID && setting.floatingEntitlementUUID,
        jobsLockDuration: setting.jobsLockDuration && setting.jobsLockDuration,
        floatingLicenseCooldownActivation:
          setting.floatingLicenseCooldownActivation && setting.floatingLicenseCooldownActivation,
        eulaEntitlementUUID: setting.eulaEntitlementUUID && setting.eulaEntitlementUUID,
        slaEntitlementUUID: setting.slaEntitlementUUID && setting.slaEntitlementUUID,
        eulaTermsText: setting.eulaTermsText && setting.eulaTermsText,
        slaTermsText: setting.slaTermsText && setting.slaTermsText,
        badgeGrantingProductNameRegex:
          setting.badgeGrantingProductNameRegex && setting.badgeGrantingProductNameRegex,
        paidLicenseDiscourseBadgeID:
          setting.paidLicenseDiscourseBadgeID && setting.paidLicenseDiscourseBadgeID,
        discourseEndpointURL: setting.discourseEndpointURL && setting.discourseEndpointURL,
        discourseAPIKey: setting.discourseAPIKey && setting.discourseAPIKey,
        cdnBaseURL: setting.cdnBaseURL && setting.cdnBaseURL
      };
    }
    return {
      licenseEncryptionKey: properties.initialLicenseEncryptionKey || '',
      rssFeedUrl: properties.initialRssFeedUrl || '',
      applicationTitle: properties.initialApplicationTitle || '',
      templateLicenseUUID: properties.initialtemplateLicenseUUID || null,
      userAuthenticationVerificationURL: properties.initialUserAuthenticationVerificationURL || '',
      selectedCSSTemplate: properties.initialSelectedCSSTemplate || '',
      cronJobSchedule: properties.initialCronJobSchedule || '0',
      supportRoleUUID: properties.initialSupportRoleUUID || '',
      renewalRefreshTokenPeriodInSeconds:
        properties.initialRenewalRefreshTokenPeriodInSeconds && 4320,
      cacheFeedsTTL: properties.initialCacheFeedsTTL || 3600,
      ecommerceBaseURL: properties.initialEcommerceBaseURL || '',
      adminEmail: properties.initialAdminEmail || '',
      activateLicenseFallbackURL: properties.initialActivateLicenseFallbackURL || '',
      experimentalFeatures: properties.initialExperimentalFeatures || '',
      rssDelay: properties.initialRssDelay || 5,
      frontendBaseURL: properties.initialFrontendBaseURL || '',
      migrationContactEmail: properties.initialMigrationContactEmail || '',
      numberOfNewsLetterEmailsSentPerMinute:
        properties.initialNumberOfNewsLetterEmailsSentPerMinute || 0,
      licenseBaseURL: properties.initialLicenseBaseURL || '',
      supportEntitlementUUID: properties.initialSupportEntitlementUUID || '',
      sublicenseEntitlementUUID: properties.initialSublicenseEntitlementUUID || '',
      floatingEntitlementUUID: properties.initialFloatingEntitlementUUID || '',
      jobsLockDuration: properties.initialJobsLockDuration || 0,
      floatingLicenseCooldownActivation: properties.initialFloatingLicenseCooldownActivation || '',
      eulaEntitlementUUID: properties.initialEulaEntitlementUUID || '',
      slaEntitlementUUID: properties.initialSLAEntitlementUUID || '',
      eulaTermsText: properties.initialEulaTermsText || '',
      slaTermsText: properties.initialSLATermsText || '',
      badgeGrantingProductNameRegex: properties.initialBadgeGrantingProductNameRegex || '',
      paidLicenseDiscourseBadgeID: properties.initialPaidLicenseDiscourseBadgeID || 0,
      discourseEndpointURL: properties.initialDiscourseEndpointURL || '',
      discourseAPIKey: properties.initialDiscourseAPIKey || '',
      cdnBaseURL: properties.initialCDNBaseURL || ''
    };
  },
  validationSchema: () => {
    // validation schema
    const validationSpec = {
      licenseEncryptionKey: Yup.string().required(
        i18n.t('validation.required', {
          field: i18n.t('admin.page.settings.form.licenseEncryptionKey')
        })
      ),
      rssFeedUrl: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .nullable()
        .url(i18n.t('admin.page.settings.errors.urlFormat')),
      applicationTitle: Yup.string().nullable(),
      userAuthenticationVerificationURL: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .url(i18n.t('admin.page.settings.errors.urlFormat'))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.userAuthenticationVerificationURL')
          })
        ),
      cronJobSchedule: Yup.string().required(
        i18n.t('validation.required', { field: i18n.t('admin.page.settings.form.cronJobSchedule') })
      ),
      supportRoleUUID: Yup.string().nullable(),
      renewalRefreshTokenPeriodInSeconds: Yup.number()
        .min(defaultRenewalRefreshTokenPeriodInSeconds, 'Must be 5 minutes or longer')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.renewalRefreshTokenPeriodInSeconds')
          })
        ),
      cacheFeedsTTL: Yup.number().nullable(),
      ecommerceBaseURL: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.ecommerceBaseURL')
          })
        )
        .url(i18n.t('admin.page.settings.errors.urlFormat')),
      adminEmail: Yup.string()
        .email()
        .required(
          i18n.t('validation.required', { field: i18n.t('admin.page.settings.form.adminEmail') })
        ),
      activateLicenseFallbackURL: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .nullable()
        .url(i18n.t('admin.page.settings.errors.urlFormat')),
      experimentalFeatures: Yup.string(),
      rssDelay: Yup.number().required(
        i18n.t('validation.required', { field: i18n.t('admin.page.settings.form.rssDelay') })
      ),
      frontendBaseURL: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.frontendBaseURL')
          })
        )
        .url(i18n.t('admin.page.settings.errors.urlFormat')),
      migrationContactEmail: Yup.string()
        .email()
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.migrationContactEmail')
          })
        ),
      numberOfNewsLetterEmailsSentPerMinute: Yup.number().required(
        i18n.t('validation.required', {
          field: i18n.t('admin.page.settings.form.numberOfNewsLetterEmailsSentPerMinute')
        })
      ),
      licenseBaseURL: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.licenseBaseURL')
          })
        )
        .url(i18n.t('admin.page.settings.errors.urlFormat')),
      supportEntitlementUUID: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.supportEntitlementUUID')
          })
        ),
      sublicenseEntitlementUUID: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.sublicenseEntitlementUUID')
          })
        ),
      floatingEntitlementUUID: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.floatingEntitlementUUID')
          })
        ),
      jobsLockDuration: Yup.number().required(
        i18n.t('validation.required', {
          field: i18n.t('admin.page.settings.form.jobsLockDuration')
        })
      ),
      floatingLicenseCooldownActivation: Yup.number().nullable(),
      eulaEntitlementUUID: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.eulaEntitlementUUID')
          })
        ),
      slaEntitlementUUID: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.slaEntitlementUUID')
          })
        ),
      slaTermsText: Yup.string()
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.slaTermsTextError')
          })
        )
        .nullable(),
      eulaTermsText: Yup.string()
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.eulaTermsTextError')
          })
        )
        .nullable(),
      badgeGrantingProductNameRegex: Yup.string().nullable(),
      paidLicenseDiscourseBadgeID: Yup.number().required(
        i18n.t('validation.required', {
          field: i18n.t('admin.page.settings.form.paidLicenseDiscourseBadgeID')
        })
      ),
      discourseEndpointURL: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.discourseEndpointURL')
          })
        )
        .url(i18n.t('admin.page.settings.errors.urlFormat')),
      discourseAPIKey: Yup.string().required(
        i18n.t('validation.required', {
          field: i18n.t('admin.page.settings.form.discourseAPIKey')
        })
      ),
      cdnBaseURL: Yup.string()
        .max(500, i18n.t('validation.max', { field: '500' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.settings.form.cdnBaseURL')
          })
        )
    };
    return Yup.object(validationSpec);
  },
  handleSubmit: (values, bags) => {
    values.floatingLicenseCooldownActivation =
      String(values.floatingLicenseCooldownActivation) === ''
        ? null
        : values.floatingLicenseCooldownActivation;
    values.cacheFeedsTTL = String(values.cacheFeedsTTL) === '' ? null : values.cacheFeedsTTL;

    if (bags.props && bags.props.settingsState.setting) {
      return bags.props.handleSubmit({
        ...values,
        id: bags.props.settingsState.setting.id
      });
    }
    return bags.props.handleSubmit({
      ...values
    });
  }
})(SettingApplicationForm);

export default SettingApplication;
